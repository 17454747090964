<template>
  <section
    ref="$block"
    class="osk-celebrity-block"
  >
    <div class="osk-wrapper">
      <h2
        v-if="title"
        class="osk-celebrity-block__title"
      >
        {{ title }}
      </h2>

      <UikitSwiper
        v-if="data?.length"
        ref="$swiper"
        off
        navigation
        :css-mode="!isDesktop"
        :breakpoints="sliderBreakpoints"
        class="osk-celebrity-block__swiper"
        @slide-change="handleSlideChange"
      >
        <UikitSwiperSlide
          v-for="(item, index) in data"
          :key="index"
        >
          <UikitCelebrityCard
            :data="item"
            :index="index"
            @click="handleClick"
          />
        </UikitSwiperSlide>
      </UikitSwiper>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { SocialAccountDTO, CelebrityDTO } from '~/restAPI/data-contracts';
import { IOptions } from '~/types/analytics';

const sliderBreakpoints = {
  0: {
    slidesPerView: 1.5,
    slidesPerGroup: 1,
    spaceBetween: 0,
    navigation: { enabled: false },
  },
  478: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 0,
    navigation: { enabled: false },
  },
  580: {
    slidesPerView: 2.5,
    slidesPerGroup: 2,
    spaceBetween: 0,
    navigation: { enabled: false },
  },
  700: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 0,
    navigation: { enabled: false },
  },
  1024: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 24,
    navigation: { enabled: true },
  },
};

const props = defineProps<{
  title?: string
  data: (SocialAccountDTO | CelebrityDTO)[] | null
  chapter?: string
  chapterId?: string
  analytics?: IOptions
}>();

const $block = ref<ComponentPublicInstance>();
const $swiper = ref();

const { isDesktop } = useUiBreakpoints();

function isSocialAccountDTO(value: SocialAccountDTO | CelebrityDTO): value is SocialAccountDTO {
  return !!(value as SocialAccountDTO).userId;
}

const allIds = computed(() => props.data?.map((p) => {
  if (isSocialAccountDTO(p)) {
    return p.userId!;
  }

  return p.id!;
}) ?? []);

const { event, clickEvent } = useSwiperAnalytics(
  $swiper,
  $block,
  allIds,
  {
    chapter: props.analytics?.chapter || props.chapter,
    chapter_index: props.analytics?.chapter_index,
    segment_id: props.analytics?.segment_id,
    id_chapter: props.analytics?.id_chapter || props.chapterId,
  },
  sliderBreakpoints,
);

function handleClick(item: { id?: string | number, index?: number }) {
  clickEvent({
    id_item: String(item.id),
    index: item.index,
    item: 'celebrity',
  });
}

function handleSlideChange() {
  event();
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-celebrity-block {
  padding-bottom: 42px;

  @include media-query(lg-and-up) {
    padding-bottom: 82px;
  }

  &__title {
    @include font-style($font-size-heading, normal, $font-weight-bold);
    padding: 0 16px 22px;

    @include media-query(lg-and-up) {
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 34px;
    }
  }

  .osk-wrapper {
    padding: 0;

    @include media-query(lg-and-up) {
      padding: 0 16px;
    }
  }

  .swiper-slide {
    padding: 0 16px;
    margin-right: -20px;

    @include media-query(lg-and-up) {
      padding: 0;
      margin: 0;
    }
  }

  .osk-swiper_off {
    .swiper-wrapper {
      overflow: visible;
      justify-content: space-between;
    }

    .swiper-slide {
      max-width: 282px;
      margin-right: 24px;
      flex-shrink: 0;
    }
  }

  &__swiper {
    .osk-swiper-button-prev,
    .osk-swiper-button-next {
      @media screen and (min-width: 700px) {
        top: -42px !important;
      }

      @include media-query(lg-and-up) {
        top: -58px !important;
      }
    }
  }
}
</style>
